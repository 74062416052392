'use client';
import ErrorWrapper from '@/features/error-tracking/components/error-wrapper/ErrorWrapper';

export default function Error({
    error,
    reset,
}: {
    error: Error & { digest?: string };
    reset: () => void;
}) {
    return <ErrorWrapper error={error} reset={reset} />;
}
