export const logError = (
    description: string,
    error: Error | string | { [key: string]: any },
    log?: boolean
) => {
    const bork = error instanceof Error ? error : new Error(JSON.stringify(error));

    bork.name = 'AMT_FE_ERROR';
    bork.cause = { description, error };

    // We want to log errors only if we expressly wish to or
    // if we're in development and logging wasn't expressly disabled
    if (log || (log !== false && process.env.NODE_ENV === 'development')) {
        console.error(bork);
    }
};
